import React from 'react';
import { Link } from 'gatsby';
import { PostData } from '../datas/post';
import { TagIcons } from './tag';

const PostListItem = ({ post }: {
  post: PostData;
}): JSX.Element => {
  const { excerpt, fields, frontmatter } = post;
  return (
    <div className="box">
      <Link style={{ color: 'inherit' }} to={fields.slug}>
        <article>
          <header>
            <h3 className="is-size-4 has-text-weight-semibold">
              {frontmatter.title}
            </h3>
            <div className="level my-3">
              <div className="level-left">
                <p className="has-text-grey is-size-7 has-text-weight-bold">{frontmatter.date}</p>
              </div>
              <div className="level-right">
                <TagIcons tags={post.frontmatter.tags} />
              </div>
            </div>
          </header>
          <div>
            <p>{excerpt}</p>
          </div>
        </article>
      </Link>
    </div>
  );
};
export default PostListItem;
