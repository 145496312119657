import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import PostListItem from '../components/post';
import { PostData } from '../datas/post';

const BlogIndex = ({ data }: {
  data: DataType;
}): JSX.Element => {
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout seo={{ title: 'トップページ' }} hero>
      <div className="container">
        {posts.map(({ node }) => (<PostListItem post={node} />))}
      </div>
    </Layout>
  );
};

export default BlogIndex;

type DataType = {
  site: {
    siteMetadata: {
      title: string;
    };
  };
  allMarkdownRemark: {
    edges: {
      node: PostData;
    }[];
  };
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(truncate: true)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
          }
        }
      }
    }
  }
`;
